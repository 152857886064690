export default [
    // *===============================================---*
    // *--------- Advertiser section ---- ---------------------------------------*
    // *===============================================---*
    {
        path: '/advertiser',
        name: 'advertiser',
        component: () => import('@/views/advertiser/home.vue'),
    },
    {
        path: '/advertiser/campaigns',
        name: 'advertiser-campaigns',
        component: () => import('@/views/advertiser/campaigns.vue'),
        meta: {
        pageTitle: 'Campaign Overview',
            breadcrumb: [
                {
                text: 'Dashboard',
                to:'/advertiser/'
                },
                {
                text: 'Campaigns',
                to:'/advertiser/campaigns'
                }
            ],
        },
    },
    {
        path: '/advertiser/campaigns/view/:id',
        name: 'advertiser-campaign-report',
        component: () => import('@/views/advertiser/report.vue'),
    },
    {
        path: '/advertiser/campaigns/create',
        name: 'advertiser-campaign-create',
        component: () => import('@/views/advertiser/campaign-create.vue'),
        meta: {
            pageTitle: 'Campaign Create',
            breadcrumb: [
                {
                    text: 'Dashboard',
                    to:'/advertiser/'
                },
                {
                    text: 'Campaigns',
                    to:'/advertiser/campaigns'
                },
                {
                    text: 'Create',
                    active:true
                }
            ],
        },
    },
    {
        path: '/advertiser/ad-groups',
        name: 'advertiser-ad-groups',
        component: () => import('@/views/advertiser/adgroups.vue'),
        meta: {
            pageTitle: 'Ad Groups',
            breadcrumb: [
                {
                text: 'Dashboard',
                to:'/advertiser/'
                },
                {
                text: 'Campaigns',
                to:'/advertiser/campaigns'
                },
            ],
        },
    },
    {
        path: '/advertiser/ad-group/create',
        name: 'advertiser-adgroup-create',
        component: () => import('@/views/advertiser/adgroup-create.vue'),
        meta: {
            pageTitle: 'Ad Group Create',
            breadcrumb: [
                {
                    text: 'Dashboard',
                    to:'/advertiser/'
                },
                {
                    text: 'Ad Groups',
                    to:'/advertiser/ad-groups'
                },
                {
                    text: 'Create',
                    active:true
                }
            ],
        },
    },
    {
        path: '/advertiser/banners',
        name: 'advertiser-banners',
        component: () => import('@/views/advertiser/banners.vue'),
        meta: {
            pageTitle: 'Banners',
            breadcrumb: [
                {
                text: 'Dashboard',
                to:'/advertiser/'
                },
                {
                text: 'Campaigns',
                to:'/advertiser/campaigns'
                },
            ],
        },
    },
    {
        path: '/advertiser/banner/create',
        name: 'advertiser-banner-create',
        component: () => import('@/views/advertiser/banner-create.vue'),
        meta: {
            pageTitle: 'Banner Create',
            breadcrumb: [
                {
                    text: 'Dashboard',
                    to:'/advertiser/'
                },
                {
                    text: 'Banners',
                    to:'/advertiser/banners'
                },
                {
                    text: 'Create',
                    active:true
                }
            ],
        },
    },
    {
        path: '/advertiser/banners/preview/:id',
        name: 'advertiser-banners-preview',
        component: () => import('@/views/advertiser/preview.vue'),
        meta: {
            layout: 'full',
            pageTitle: 'Banner Preview',
        },
    },
]