export default [
  {
    path: '/dashboard/home',
    name: 'dashboard-home',
    component: () => import('@/views/authentication/loggeduser.vue'),
  },
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/publisher',
    name: 'publisher',
    component: () => import('@/views/publisher/home.vue'),
  },
  // *===============================================---*
  // *--------- Publisher Real Time ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/publisher/realtime',
    name: 'publisher-realtime',
    component: () => import('@/views/publisher/realtime.vue'),
    meta: {
      pageTitle: 'Real Time',
      breadcrumb: [
        {
          text: 'Dashboard',
          to:'/publisher'
        },
        {
          text: 'Real Time',
        },
        {
          text: 'Visitor',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- Publisher Forecast ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/publisher/forecast',
    name: 'publisher-forecast',
    component: () => import('@/views/publisher/forecast.vue'),
    meta: {
      pageTitle: 'Forecast',
      breadcrumb: [
        {
          text: 'Dashboard',
          to:'/publisher/'
        },
        {
          text: 'Forecast',
          active: true,
        },
      ],
    },
  },
  {
    path: '/publisher/slots',
    name: 'publisher-slots',
    component: () => import('@/views/inventory/slots.vue'),
    meta: {
      pageTitle: 'Inventory Slots',
      breadcrumb: [
        {
          text: 'Dashboard',
        },
        {
          text: 'Inventory',
        },
        {
          text: 'Slots',
          active: true,
        },
      ],
    },
  },
  {
    path: '/publisher/payments',
    name: 'publisher-payments',
    component: () => import('@/views/payments/payment.vue'),
    meta: {
      pageTitle: 'Payment Information',
      breadcrumb: [
        {
          text: 'Dashboard',
          to:'/publisher/'
        },
        {
          text: 'Payments',
        },
        {
          text: 'Payment Information',
          active: true,
        },
      ],
    },
  },
  {
    path: '/publisher/payment-method',
    name: 'publisher-payment-method',
    component: () => import('@/views/payments/method.vue'),
    meta: {
      pageTitle: 'Payment Method',
      breadcrumb: [
        {
          text: 'Dashboard',
          to:'/publisher/'
        },
        {
          text: 'Payments',
          to:'/publisher/payments'
        },
        {
          text: 'Payment Method',
          active: true,
        },
      ],
    },
  },
  {
    path: '/publisher/slot/create',
    name: 'publisher-slot-create',
    component: () => import('@/views/inventory/createSlot.vue'),
    meta: {
      pageTitle: 'Create Slot',
      breadcrumb: [
        {
          text: 'Dashboard',
          to:'/publisher/'
        },
        {
          text: 'Slot',
          to: '/publisher/slots/',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/publisher/slot/edit/:id',
    name: 'publisher-slot-edit',
    component: () => import('@/views/inventory/createSlot.vue'),
    meta: {
      pageTitle: 'Edit Slot',
      breadcrumb: [
        {
          text: 'Dashboard',
          to:'/publisher/'
        },
        {
          text: 'Slot',
          to: '/publisher/slots/',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- Inventory Section ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/publisher/inventory',
    name: 'inventory',
    component: () => import('@/views/inventory/publishers.vue'),
    meta: {
      pageTitle: 'Inventory',
      breadcrumb: [
        {
          text: 'Dashboard',
          to:'/publisher/'
        },
        {
          text: 'Inventory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/publisher/inventory/create',
    name: 'publisher-inventory-create',
    component: () => import('@/views/inventory/createInventory.vue'),
    meta: {
      pageTitle: 'Create Inventory',
      breadcrumb: [
        {
          text: 'Dashboard',
          to:'/publisher/'
        },
        {
          text: 'Inventory',
          to:'/publisher/inventory/'
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/publisher/inventory/edit/:id',
    name: 'publisher-inventory-edit',
    component: () => import('@/views/inventory/editInventory.vue'),
    meta: {
      pageTitle: 'Edit Inventory',
      breadcrumb: [
        {
          text: 'Dashboard',
          to:'/publisher/'
        },
        {
          text: 'Inventory',
          to:'/publisher/inventory/'
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- Protections ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/publisher/protections',
    name: 'publisher-protections',
    component: () => import('@/views/publisher/protections.vue'),
    meta: {
      pageTitle: 'Inventory Protection',
      breadcrumb: [
        {
          text: 'Dashboard',
          to:'/publisher/'
        },
        {
          text: 'Protection',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- Reports ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/publisher/reports',
    name: 'publisher-reports',
    component: () => import('@/views/publisher/reports.vue'),
    meta: {
      pageTitle: 'Inventory Reports',
      breadcrumb: [
        {
          text: 'Dashboard',
          to:'/publisher/'
        },
        {
          text: 'Reports',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- Administrator ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/admin/dashboard.vue'),
  },

    // *===============================================---*
  // *--------- Others ---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },

  {
    path: '/profile-setting',
    name: 'profile-setting',
    component: () => import('@/views/profile/ProfileSetting.vue'),
    meta: {
      pageTitle: 'Profile Settings',
      breadcrumb: [
        {
          text: 'Profile Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        },
      ],
    },
  },

  {
    path: '/pages/faq',
    name: 'pages-faq',
    component: () => import('@/views/pages/faq/Faq.vue'),
    meta: {
      pageTitle: 'FAQ',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'FAQ',
          active: true,
        },
      ],
    },
  },
  
]
